import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap"
import Select from "react-select"
import { useState } from "react"
import axios from "axios";

const optionGroup = [
    {
        label: "Status",
        options: [
            { label: "Approved", value: "Approved" },
            { label: "Rejected", value: "Rejected" },
            { label: "Pending", value: "Pending" },
        ],
    },
]

const Status = ({ modal, toggle, id, handleStatus, selectedGroup, setselectedGroup }) => {
    document.title = "Form | OnlineKorp Admin Dashboard"
    const handleSelectGroup = selectedGroup => {
        setselectedGroup(selectedGroup)
    }


    return (
        <>
            <div>
                {/* <form
            inline
            onSubmit={e => {
              e.preventDefault()
              validationType.handleSubmit()
              return false
            }}
          > */}
                <Modal isOpen={modal} toggle={toggle} className="w-100">
                    <ModalHeader>Status</ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            <Label className="form-label">Select Option</Label>
                            <Select
                                value={selectedGroup}
                                onChange={handleSelectGroup}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex flex-wrap gap-2">
                            <Button type="submit" color="primary" onClick={() => handleStatus(id)}>
                                Update
                            </Button>{" "}
                            <Button type="reset" color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* </form> */}
            </div>
        </>
    )
}

export default Status;
