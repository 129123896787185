import axios from "axios"
import { toast } from "react-toastify";
import { Alert } from "reactstrap";

//pass new generated access token here
const token = "";

//apply base url for axios
const API_URL = "http://localhost:8080/api/user"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}


export async function post(url, data, config = {}) {
  console.log("login here..", url, data);
  // Construct the full URL
   let apiURL = `https://apionlinekorp.radarsofttech.in`;
  //let apiURL = `http://localhost:8080`;
  // let apiURL = `http://localhost:5006`;
  const fullUrl = `${apiURL}/api/user/${url}`;
  // Prepare the fetch options
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...config.headers, // Merge any additional headers from the config
    },
    body: JSON.stringify(data), // Convert the data to a JSON string
    ...config, // Merge any other config options
  };
  try {
    const response = await fetch(fullUrl, fetchOptions);
    // Check if the response is okay
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }
    // Parse the JSON response
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error in POST request:", error);
    // alert("Invalid Credentials.");
    toast("Invalid credentials!")
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}
export async function remove(url, data, config = {}) {
  localStorage.removeItem("authUser");
  localStorage.Admintoken("Admintoken");
  return true;
}


export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
