import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")

const GoAmlDetails = () => {
    const { goAmlID } = useParams()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true) // New loading state
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate();
    const apiURL = `https://apionlinekorp.radarsofttech.in`
    //let apiURL = `http://localhost:5006`;
    // let apiURL = `http://localhost:8080`;

    document.title = "Service Details | Admin & Dashboard"

    const fetchVisaDetails = async () => {
        try {
            setLoading(true)
            const response = await fetch(
                `${apiURL}/api/admin/goaml/details/${goAmlID}`,
                {
                    method: "GET", // GET is the default method, but it's good practice to specify it explicitly
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const res = await response.json()
            console.log("res premium visa details", res)
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                  }
                console.log("res premium visa details", res.data)
                setData(res.data)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false) // Set loading to false after fetching
        }
    }

    useEffect(() => {
        fetchVisaDetails()
    }, [])

    const download = e => {
        e.preventDefault()

        const url = e.target.href
        console.log(url)

        fetch(url, {
            method: "GET",
            headers: {
                // You can add custom headers here if needed
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok")
                }
                return response.arrayBuffer()
            })
            .then(buffer => {
                const blob = new Blob([buffer])
                const link = document.createElement("a")
                const fileName = url.split("/").pop() // Extract file name from URL
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName // Use the extracted file name
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // Clean up
                window.URL.revokeObjectURL(link.href) // Release the object URL
            })
            .catch(err => {
                console.error("Download failed:", err)
            })
    }

    console.log("visa Data", data)

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Row>
                        <Col lg={12}>
                            <Card style={{ padding: "20px" }}>
                                <p style={{ fontSize: "24px", fontWeight: "600" }}>
                                    {visaData?.serviceName} Service
                                </p>
                                <div
                                    style={{ display: "flex", justifyContent: "space-between" }}
                                >
                                    <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                                        {" "}
                                        <strong> Jurisdiction : </strong>{" "}
                                        <span style={{ textAlign: "end" }}>
                                            {visaData?.jurisdiction}{" "}
                                        </span>
                                    </Col>
                                    <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                                        {" "}
                                        <strong> No Of Applicants : </strong>{" "}
                                        {visaData?.noOfApplicants}
                                    </Col>
                                </div>
                                <div
                                    style={{ display: "flex", justifyContent: "space-between" }}
                                >
                                    <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                                        <strong>Application Status :</strong> {visaData?.status}
                                    </Col>
                                    <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                                        <strong> Applied Date : </strong>
                                        {visaData?.createdAt?.substring(0, 10)}
                                    </Col>
                                </div>
                            </Card>
                            <div style={{ marginTop: "10px" }}>

                                <Card>
                                    <div style={{ padding: "20px" }}>
                                        <p style={{ fontSize: "20px", fontWeight: "600" }}>
                                            Applicants Details
                                        </p>


                                        {loading ? (
                                            <div style={{ textAlign: "center", padding: "20px", display: "flex", justifyContent: "center" }}>
                                                <ThreeDots
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#6f42c1"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) :
                                            (
                                                <Row>
                                                    {visaData && visaData?.applicants?.length > 0 ?
                                                        visaData?.applicants?.map((el, i) => (
                                                            <>
                                                                <h4>Applicant {i + 1}</h4>
                                                                <Col lg={12} style={{ marginTop: "10px" }}>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <Col lg={6}>
                                                                            <p><strong>FullName</strong>  :<span>{el?.name} </span></p>
                                                                            <p><strong>Country Of Birth</strong> : {el?.countryOfBirth}</p>
                                                                            <p><strong>Nationality</strong> : {el?.nationality}</p>
                                                                            <p><strong>DOB</strong> : {el?.dob?.substring(0, 10)}</p>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <p><strong>Gender</strong>  : {el?.gender}</p>
                                                                            <p><strong>Marital Status</strong> :{el?.maritalStatus}</p>
                                                                            <p><strong>Relation</strong> : {el?.relation}</p>
                                                                            <p><strong>Country</strong>: {el?.country}</p>
                                                                            <p>
                                                                                <strong>Current Profession</strong>  : {el?.currentProfession}
                                                                            </p>
                                                                        </Col>
                                                                    </div>
                                                                    <div style={{ marginTop: "5px" }}>
                                                                        <h4>Contact Details</h4>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                marginTop: "20px",
                                                                            }}
                                                                        >
                                                                            <Col lg={6}>
                                                                                <p><strong>Email</strong>  : {el?.contactDetails?.email}</p>
                                                                                <p>
                                                                                    <strong>Mobile No</strong>    : {el?.contactDetails?.mobileNo}
                                                                                </p>
                                                                            </Col>
                                                                            <Col lg={6}>
                                                                                <p>
                                                                                    <strong> TelePhone No</strong>    :{" "}
                                                                                    {el?.contactDetails?.phoneNumber}
                                                                                </p>
                                                                                <p>  <strong>Address</strong>  : {el?.contactDetails?.address}</p>
                                                                            </Col>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <Col lg={6} style={{ marginTop: "5px" }}>
                                                                            <h4>Health Details</h4>
                                                                            <p>
                                                                                <strong> Any Infection</strong>   :{" "}
                                                                                {el?.healthDetails?.infection ? "Yes" : "No"}
                                                                            </p>
                                                                        </Col>
                                                                        <Col lg={6} style={{ marginTop: "5px" }}>
                                                                            <h4>Income Details</h4>
                                                                            <p>
                                                                                <strong> Income Currency</strong>     :{" "}
                                                                                {el?.incomeRevenue?.currency}
                                                                            </p>
                                                                        </Col>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            marginTop: "5px",
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            justifyItems: "start",
                                                                        }}
                                                                    >
                                                                        <Col lg={6}>
                                                                            <h4 style={{ marginTop: "5px" }}>
                                                                                Passport Details
                                                                            </h4>
                                                                            <p>
                                                                                <strong>Application Type</strong>        :{" "}
                                                                                {el?.renewal?.applicationType}
                                                                            </p>
                                                                            {el?.renewal?.premiumVisaNo && (
                                                                                <p>
                                                                                    <strong> Premium Visa No</strong>       :{" "}
                                                                                    {el?.renewal?.premiumVisaNo}
                                                                                </p>
                                                                            )}
                                                                            {el?.renewal?.validateDate && (
                                                                                <p>
                                                                                    <strong> Validate Date</strong>          :{" "}
                                                                                    {el?.renewal?.validateDate?.substring(0, 10)}
                                                                                </p>
                                                                            )}
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <h4>Security Details</h4>
                                                                            <p>
                                                                                <strong> Any Criminal cases?</strong>           :{" "}
                                                                                {el?.securityDetails?.criminalCases
                                                                                    ? "Yes"
                                                                                    : "No"}
                                                                            </p>
                                                                        </Col>
                                                                    </div>
                                                                    <div>
                                                                        <h4>Stay Details</h4>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <Col lg={6} style={{ marginTop: "5px" }}>
                                                                                <p>
                                                                                    <strong>  Arrival Date</strong>          :{" "}
                                                                                    {el?.stayDetails?.arrivalDate?.substring(0, 10)}
                                                                                </p>
                                                                                <p>
                                                                                    <strong> Departure Date</strong> :{" "}
                                                                                    {el?.stayDetails?.departureDate?.substring(0, 10)}
                                                                                </p>
                                                                            </Col>
                                                                            <Col lg={6} style={{ marginTop: "5px" }}>
                                                                                <p>  <strong> Stay Type</strong>  : {el?.stayDetails?.type}</p>
                                                                                <p>
                                                                                    <strong>  Stay Descriptions</strong>    :{" "}
                                                                                    {el?.stayDetails?.workTypeDescription}
                                                                                </p>
                                                                            </Col>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h4>Documents</h4>
                                                                        <Col lg={6}>
                                                                            <div>

                                                                                {
                                                                                    el?.documents?.passport &&
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                <strong>  Passport</strong>
                                                                                                : </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el?.documents?.passport}
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el?.documents?.passport}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>

                                                                                }
                                                                                {
                                                                                    el?.documents?.photo &&
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>  <strong>  Photo</strong>    : </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a href={el?.documents?.photo} download
                                                                                                onClick={e => download(e)}>
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el.documents?.photo}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>

                                                                                }

                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        )) :
                                                        (

                                                            <div>
                                                                <p colSpan="6" style={{ textAlign: "center", padding: "20px" }}>
                                                                    Applicant Details Not Available.
                                                                </p>
                                                            </div>
                                                        )
                                                    }
                                                </Row>
                                            )
                                        }

                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row> */}
                    <div className="user-details">
                        <h2 style={{ margin: "15px 0px" }}>GoAML Service Details</h2>

                        {data ?
                            <>
                                {data?.registrationType == "Organisation" ? (
                                    <>
                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>Organisation Information</h3>
                                            <div style={{ display: "flex", gap: "20%", marginTop: "20px" }}>
                                                <div>
                                                    <p>
                                                        <strong>Full Name MLRO:</strong> {data?.fullNameMLRO}
                                                    </p>
                                                    <p>
                                                        <strong>Status:</strong> {data?.status}
                                                    </p>
                                                    <p>
                                                        <strong>Application Status:</strong> {data?.active}
                                                    </p>
                                                    <p>
                                                        <strong>Jurisdiction:</strong> {data?.jurisdiction}
                                                    </p>
                                                    <p>
                                                        <strong>Organisation Business Type:</strong>{" "}
                                                        {data?.organisationBusinessType}
                                                    </p>
                                                    <p>
                                                        <strong>Registration Type:</strong> {data?.registrationType}
                                                    </p>
                                                    <p>
                                                        <strong>Institution Name:</strong> {data?.institutionName}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <strong>Incorporation Number:</strong>{" "}
                                                        {data?.incorporationNumber}
                                                    </p>
                                                    <p>
                                                        <strong>Incorporation Legal Form:</strong>{" "}
                                                        {data?.incorporationLegalForm}
                                                    </p>
                                                    <p>
                                                        <strong>Organisation Phone Number:</strong>{" "}
                                                        {data?.phoneNumber}
                                                    </p>
                                                    <p>
                                                        <strong>TAN Number:</strong> {data?.tan}
                                                    </p>
                                                    <p>
                                                        <strong>Created At:</strong>{" "}
                                                        {new Date(data?.createdAt).toLocaleString()}
                                                    </p>
                                                    <p>
                                                        <strong>Updated At:</strong>{" "}
                                                        {new Date(data?.updatedAt).toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="details-section">
                                            <h3 style={{ margin: "20px 0px" }}>Organisation Contact Details</h3>
                                            <p>
                                                <strong>Phone Number:</strong> {data?.phoneNumber}
                                            </p>
                                            <p>
                                                <strong>Address:</strong>{" "}
                                                {data?.organisationAddressDetails?.address}
                                            </p>
                                            <p>
                                                <strong>City:</strong>{" "}
                                                {data?.organisationAddressDetails?.city}
                                            </p>
                                            <p>
                                                <strong>Town:</strong>{" "}
                                                {data?.organisationAddressDetails?.town}
                                            </p>
                                        </div>

                                        {/* <div className="details-section">
                                    <h3>User Information</h3>
                                    <p>
                                        <strong>Full Name:</strong> {data?.userId?.fullName}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {data?.userId?.email}
                                    </p>
                                </div> */}
                                        <div className="details-section">
                                            <h3>MLRO Person Details</h3>
                                            <div style={{ display: "flex", gap: "20%", marginTop: "20px" }}>
                                                <div>  <p>
                                                    <strong>First Name:</strong>{" "}
                                                    {data?.mlroPersonDetails?.firstName}
                                                </p>
                                                    <p>
                                                        <strong>Last Name:</strong>{" "}
                                                        {data?.mlroPersonDetails?.lastName}
                                                    </p>
                                                    <p>
                                                        <strong>Email:</strong> {data?.mlroPersonDetails?.email}
                                                    </p>

                                                    <p>
                                                        <strong>Occupation:</strong>{" "}
                                                        {data?.mlroPersonDetails?.occupation}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <strong>Gender:</strong> {data?.mlroPersonDetails?.gender}
                                                    </p>
                                                    <p>
                                                        <strong>Date of Birth:</strong>{" "}
                                                        {data?.mlroPersonDetails?.dob}
                                                    </p>
                                                    <p>
                                                        <strong>Title:</strong> {data?.mlroPersonDetails?.title}
                                                    </p>
                                                    <p>
                                                        <strong>NIC:</strong> {data?.mlroPersonDetails?.nic}
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>MLRO Contact Details</h3>
                                            <p>
                                                <strong>Phone Number:</strong>{" "}
                                                {data?.mlroPersonDetails?.contactMLRODetails?.phoneNumber}
                                            </p>
                                        </div>
                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>Passport Details</h3>
                                            <p>
                                                <strong>Do you have passport? :</strong>{" "}
                                                {data?.mlroPersonDetails?.passportDetails?.isPassport}
                                            </p>
                                            <p>
                                                <strong>Passport Number:</strong>{" "}
                                                {data?.mlroPersonDetails?.passportDetails?.passportNumber}
                                            </p>
                                            <p>
                                                <strong>Passport Country:</strong>{" "}
                                                {data?.mlroPersonDetails?.passportDetails?.passportCountry}
                                            </p>
                                        </div>

                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>Package Information</h3>
                                            <p>
                                                <strong>Package Type:</strong> {data?.package?.packageType}
                                            </p>
                                            <p>
                                                <strong>Package Price:</strong>{" "}
                                                {data?.package?.packagePrice}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* <div className="details-section">
                                    <h3>User Information</h3>
                                    <p>
                                        <strong>Full Name:</strong> {data?.userId?.fullName}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {data?.userId?.email}
                                    </p>
                                </div> */}
                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>Person Details</h3>
                                            <div style={{ display: "flex", gap: "20%", marginTop: "20px" }}>
                                                <div>
                                                    <p>
                                                        <strong>First Name:</strong> {data?.firstName}
                                                    </p>
                                                    <p>
                                                        <strong>Last Name:</strong> {data?.lastName}
                                                    </p>
                                                    <p>
                                                        <strong>BRN Number:</strong> {data?.brnNumber}
                                                    </p>
                                                    <p>
                                                        <strong>Email:</strong> {data?.email}
                                                    </p>
                                                    <p>
                                                        <strong>Gender:</strong> {data?.gender}
                                                    </p>
                                                    <p>
                                                        <strong>Date of Birth:</strong> {data?.dob}
                                                    </p>
                                                    <p>
                                                        <strong>Registration Type:</strong> {data?.registrationType}
                                                    </p>
                                                    <p>
                                                        <strong>Created At:</strong>{" "}
                                                        {new Date(data?.createdAt).toLocaleString()}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <strong>Title:</strong> {data?.title}
                                                    </p>
                                                    <p>
                                                        <strong>NIC:</strong> {data?.nic}
                                                    </p>
                                                    <p>
                                                        <strong>Occupation:</strong> {data?.occupation}
                                                    </p>
                                                    <p>
                                                        <strong>Status:</strong> {data?.status}
                                                    </p>
                                                    <p>
                                                        <strong>Application Status:</strong> {data?.active}
                                                    </p>
                                                    <p>
                                                        <strong>Jurisdiction:</strong> {data?.jurisdiction}
                                                    </p>
                                                    <p>
                                                        <strong>Updated At:</strong>{" "}
                                                        {new Date(data?.updatedAt).toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>




                                        </div>
                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>Contact Details</h3>
                                            <p>
                                                <strong>Phone Number:</strong>{" "}
                                                {data?.contactDetails?.phoneNumber}
                                            </p>
                                            <p>
                                                <strong>Address:</strong> {data?.addressDetails?.address}
                                            </p>
                                            <p>
                                                <strong>City:</strong> {data?.addressDetails?.city}
                                            </p>
                                            <p>
                                                <strong>Town:</strong> {data?.addressDetails?.town}
                                            </p>
                                        </div>
                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>Passport Details</h3>
                                            <p>
                                                <strong>Do you have passport? :</strong>{" "}
                                                {data?.passportDetails?.isPassport}
                                            </p>
                                            {data?.passportDetails?.isPassport == "yes" && (
                                                <>
                                                    <p>
                                                        <strong>Passport Number:</strong>{" "}
                                                        {data?.passportDetails?.passportNumber}
                                                    </p>
                                                    <p>
                                                        <strong>Passport Country:</strong>{" "}
                                                        {data?.passportDetails?.passportCountry}
                                                    </p>
                                                </>
                                            )}
                                        </div>

                                        <div className="details-section">
                                            <h3 style={{ margin: "10px 0px" }}>Package Information</h3>
                                            <p>
                                                <strong>Package Type:</strong> {data?.package?.packageType}
                                            </p>
                                            <p>
                                                <strong>Package Price:</strong>{" "}
                                                {data?.package?.packagePrice}
                                            </p>
                                        </div>
                                    </>
                                )}

                                <div>
                                    <h4 style={{ margin: "10px 0px" }}>Documents</h4>
                                    {data?.registrationType == "Organisation" ? (
                                        <Col lg={8}>
                                            <div>
                                                {data?.documents?.certificate_TAN && (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        {" "}
                                                        <Col lg={4}>
                                                            {" "}
                                                            <p>
                                                                <strong> Certificate TAN</strong>:{" "}
                                                            </p>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={data?.documents?.certificate_TAN}
                                                                download
                                                                onClick={e => download(e)}
                                                            >
                                                                Download
                                                            </a>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={data?.documents?.certificate_TAN}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                View Document
                                                            </a>
                                                        </Col>
                                                    </div>
                                                )}
                                                {data?.documents?.certificate_organisation_Brn && (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        {" "}
                                                        <Col lg={4}>
                                                            {" "}
                                                            <p>
                                                                {" "}
                                                                <strong>
                                                                    {" "}
                                                                    Certificate Organisation BRN
                                                                </strong> :{" "}
                                                            </p>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={data?.documents?.certificate_organisation_Brn}
                                                                download
                                                                onClick={e => download(e)}
                                                            >
                                                                Download
                                                            </a>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={data.documents?.certificate_organisation_Brn}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                View Document
                                                            </a>
                                                        </Col>
                                                    </div>
                                                )}
                                                {data?.documents?.certificate_organisation_COI && (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        {" "}
                                                        <Col lg={4}>
                                                            {" "}
                                                            <p>
                                                                {" "}
                                                                <strong>
                                                                    {" "}
                                                                    Certificate Organisation COI
                                                                </strong> :{" "}
                                                            </p>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={data?.documents?.certificate_organisation_COI}
                                                                download
                                                                onClick={e => download(e)}
                                                            >
                                                                Download
                                                            </a>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={data.documents?.certificate_organisation_COI}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                View Document
                                                            </a>
                                                        </Col>
                                                    </div>
                                                )}
                                                {data?.documents
                                                    ?.certificate_organisation_PassportOrNid && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            {" "}
                                                            <Col lg={4}>
                                                                {" "}
                                                                <p>
                                                                    {" "}
                                                                    <strong>
                                                                        {" "}
                                                                        Certificate Organisation Passport/NID
                                                                    </strong>{" "}
                                                                    :{" "}
                                                                </p>{" "}
                                                            </Col>
                                                            <Col lg={4}>
                                                                {" "}
                                                                <a
                                                                    href={
                                                                        data?.documents
                                                                            ?.certificate_organisation_PassportOrNid
                                                                    }
                                                                    download
                                                                    onClick={e => download(e)}
                                                                >
                                                                    Download
                                                                </a>{" "}
                                                            </Col>
                                                            <Col lg={4}>
                                                                {" "}
                                                                <a
                                                                    href={
                                                                        data?.documents
                                                                            ?.certificate_organisation_PassportOrNid
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    View Document
                                                                </a>
                                                            </Col>
                                                        </div>
                                                    )}
                                                {data?.documents
                                                    ?.certificate_organisation_Proof_Address && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            {" "}
                                                            <Col lg={4}>
                                                                {" "}
                                                                <p>
                                                                    {" "}
                                                                    <strong>
                                                                        {" "}
                                                                        Certificate Organisation Proof Address
                                                                    </strong>{" "}
                                                                    :{" "}
                                                                </p>{" "}
                                                            </Col>
                                                            <Col lg={4}>
                                                                {" "}
                                                                <a
                                                                    href={
                                                                        data?.documents
                                                                            ?.certificate_organisation_Proof_Address
                                                                    }
                                                                    download
                                                                    onClick={e => download(e)}
                                                                >
                                                                    Download
                                                                </a>{" "}
                                                            </Col>
                                                            <Col lg={4}>
                                                                {" "}
                                                                <a
                                                                    href={
                                                                        data?.documents
                                                                            ?.certificate_organisation_Proof_Address
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    View Document
                                                                </a>
                                                            </Col>
                                                        </div>
                                                    )}
                                                {data?.documents?.director_Authorisation_letter && (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        {" "}
                                                        <Col lg={4}>
                                                            {" "}
                                                            <p>
                                                                {" "}
                                                                <strong>
                                                                    {" "}
                                                                    Director Authorisation Letter
                                                                </strong> :{" "}
                                                            </p>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={
                                                                    data?.documents?.director_Authorisation_letter
                                                                }
                                                                download
                                                                onClick={e => download(e)}
                                                            >
                                                                Download
                                                            </a>{" "}
                                                        </Col>
                                                        <Col lg={4}>
                                                            {" "}
                                                            <a
                                                                href={
                                                                    data?.documents?.director_Authorisation_letter
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                View Document
                                                            </a>
                                                        </Col>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    ) : (
                                        <Col lg={8}>
                                            {data?.documents?.certificateIndividualProofAddress && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    {" "}
                                                    <Col lg={4}>
                                                        {" "}
                                                        <p>
                                                            <strong>
                                                                {" "}
                                                                Certificate Of Individual Proof Address
                                                            </strong>
                                                            :{" "}
                                                        </p>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={
                                                                data?.documents?.certificateIndividualProofAddress
                                                            }
                                                            download
                                                            onClick={e => download(e)}
                                                        >
                                                            Download
                                                        </a>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={
                                                                data?.documents?.certificateIndividualProofAddress
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Document
                                                        </a>
                                                    </Col>
                                                </div>
                                            )}
                                            {data?.documents?.certificateIndividualLicence && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    {" "}
                                                    <Col lg={4}>
                                                        {" "}
                                                        <p>
                                                            {" "}
                                                            <strong>Certificate Individual Licence</strong> :{" "}
                                                        </p>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={data?.documents?.certificateIndividualLicence}
                                                            download
                                                            onClick={e => download(e)}
                                                        >
                                                            Download
                                                        </a>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={data.documents?.certificateIndividualLicence}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Document
                                                        </a>
                                                    </Col>
                                                </div>
                                            )}
                                            {data?.documents?.certificateIndividualBrn && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    {" "}
                                                    <Col lg={4}>
                                                        {" "}
                                                        <p>
                                                            {" "}
                                                            <strong> Certificate Individual BRN</strong> :{" "}
                                                        </p>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={data?.documents?.certificateIndividualBrn}
                                                            download
                                                            onClick={e => download(e)}
                                                        >
                                                            Download
                                                        </a>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={data.documents?.certificateIndividualBrn}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Document
                                                        </a>
                                                    </Col>
                                                </div>
                                            )}
                                            {data?.documents?.certificateIndividualPassportOrNid && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    {" "}
                                                    <Col lg={4}>
                                                        {" "}
                                                        <p>
                                                            {" "}
                                                            <strong>
                                                                {" "}
                                                                Certificate Individual Passport/NID
                                                            </strong>{" "}
                                                            :{" "}
                                                        </p>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={
                                                                data?.documents?.certificateIndividualPassportOrNid
                                                            }
                                                            download
                                                            onClick={e => download(e)}
                                                        >
                                                            Download
                                                        </a>{" "}
                                                    </Col>
                                                    <Col lg={4}>
                                                        {" "}
                                                        <a
                                                            href={
                                                                data?.documents?.certificateIndividualPassportOrNid
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Document
                                                        </a>
                                                    </Col>
                                                </div>
                                            )}
                                        </Col>
                                    )}
                                </div>
                            </> : (
                                <div>
                                    <p
                                        colSpan="6"
                                        style={{ textAlign: "center", padding: "20px" }}
                                    >
                                        Service Details Not Available.
                                    </p>
                                </div>
                            )
                        }


                    </div>
                </div>
            </div>
        </>
    )
}

export default GoAmlDetails
