import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"
import { useState } from "react"
import axios from "axios";

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
    ],
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
    ],
  },
]

const User = ({ modal, toggle }) => {
  // Form validation
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedGroup, setselectedGroup] = useState(null)
  document.title = "Form | OnlineKorp Admin Dashboard"
  const handleSelectGroup = selectedGroup => {
    setselectedGroup(selectedGroup)
  }

  const handleFile = event => {
    const file = event.target.files[0]
    setSelectedFile(file)
  }

  // Function to handle file upload
  const handleFileUpload = async () => {
    console.log("function called.");
    try {
      const response = await axios.post("http://localhost:8080/upload", {
        name: "vinayak",
      })
      console.log("File uploaded successfully:", response.data)
    } catch (error) {
      console.error("Error uploading file:", error)
    }
  }

  console.log("file catched suvvessfully.", selectedFile)

  return (
    <>
      <div>
        {/* <form
          inline
          onSubmit={e => {
            e.preventDefault()
            validationType.handleSubmit()
            return false
          }}
        > */}
        <Modal isOpen={modal} toggle={toggle} className="w-100">
          <ModalHeader>Add User</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="username"
                    placeholder="Type Something"
                    type="text"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label>Enter Password</Label>
                  <Input
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="username"
                    placeholder="Type Something"
                    type="text"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label>Enter Password</Label>
                  <Input
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <Label className="form-label">Select Option</Label>
              <Select
                value={selectedGroup}
                onChange={handleSelectGroup}
                options={optionGroup}
                classNamePrefix="select2-selection"
              />
            </div>
            <Row className="mb-3">
              <Label>Check</Label>
              <Col md={3}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> One
                  </Label>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Two
                  </Label>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Three
                  </Label>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Four
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-3 align-items-center">
              <Col md={6}>
                <Label className="form-label">Aadhar Card</Label>
                <Input
                  type="file"
                  onChange={handleFile}
                  className="form-control-file"
                  id="exampleFormControlFile1"
                />
              </Col>
              <Col md={6}>
                <Label className="form-label">Pan Card</Label>
                <Input
                  type="file"
                  className="form-control-file"
                  id="exampleFormControlFile1"
                />{" "}
              </Col>
            </Row>

            {/* <div className="mb-3">
                <Label className="form-label">Details</Label>
                <Input
                  type="textarea"
                  placeholder="Write something (data should remain in modal if unmountOnClose is set to false)"
                  rows={5}
                />
              </div> */}
          </ModalBody>
          <ModalFooter>
            <div className="d-flex flex-wrap gap-2">
              <Button type="submit" color="primary" onClick={handleFileUpload}>
                Add
              </Button>{" "}
              <Button type="reset" color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* </form> */}
      </div>
    </>
  )
}

export default User
