import { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { ThreeDots } from "react-loader-spinner"
import "../Dashboard/services/datatables.scss"
import { MDBDataTable } from "mdbreact"
const Users = () => {
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true) // New loading state
  const navigate = useNavigate()
  const token = localStorage.getItem("Admintoken")
   const apiURL = `https://apionlinekorp.radarsofttech.in`
  //let apiURL = `http://localhost:5006`;
  //let apiURL = `http://localhost:8080`;

  document.title = "Users | Admin & Dashboard"

  const fetchUserslist = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${apiURL}/api/admin/user`, {
        method: "GET", // GET is the default method, but it's good practice to specify it explicitly
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      const res = await response.json()
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        setUserList(res.data)
        console.log("res users list...............", res)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false) // Set loading to false after fetching
    }
  }

  useEffect(() => {
    fetchUserslist()
  }, [])

  const handleServices = id => {
    return navigate(`/service/${id}`)
  }

  const rows = userList?.map((user, i) => ({
    ...user,
    ID: i + 1,
    fullName: (
      <p
        style={{ cursor: "pointer", color: "blue" }}
        onClick={() => handleServices(user?._id)}
      >
        {user?.fullName}
      </p>
    ),
    Country: "Mauritius",
    createdAt: user.createdAt?.substring(0, 10),
    mobile: "6345354526",
  }))

  //datatables here.
  const data = {
    columns: [
      {
        label: "#ID",
        field: "ID",
        sort: "asc",
        width: 100,
      },
      {
        label: "Full Name",
        field: "fullName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Country",
        field: "Country",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile No",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      {
        label: "Create Date",
        field: "createdAt",
        sort: "asc",
        width: 150,
      },
    ],
    rows,
  }

  console.log("userlst")

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="OnlineKorp"
            title="Visa"
            breadcrumbItem="Visa Users"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#6f42c1"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <MDBDataTable responsive striped bordered data={data} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Users
