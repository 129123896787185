import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useEffect, useState } from "react"
import Visa from "components/AllTables/Visa"
import Status from "components/Common/model/Status"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { ThreeDots } from "react-loader-spinner"
import { MDBDataTable } from "mdbreact"

export const VisaService = () => {
  const [modal, setModal] = useState(false)
  const [updateID, setUpdateID] = useState(null)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = `https://apionlinekorp.radarsofttech.in`
  // let apiURL = `http://localhost:5006`;
  //let apiURL = `http://localhost:8080`;
  const [visaList, setVisaList] = useState([])

  const toggle = (itemID = null) => {
    setModal(!modal)
    setUpdateID(itemID)
  }

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `https://onlinekorpweb.radarsofttech.in/api/admin/user/66a761ea8873a87b3ea261b6`,
        {
          method: "GET", // GET is the default method, but it's good practice to specify it explicitly
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()
      if (res) {
        console.log("res getting", res)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    }
  }

  const fetchPremiumlist = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${apiURL}/api/admin`, {
        method: "GET", // GET is the default method, but it's good practice to specify it explicitly
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      const res = await response.json()
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        setVisaList(res.data)
        console.log("res premium visa list.................................", res)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false) // Set loading to false after fetching
    }
  }

  const fetchPremiumVisaDetails = async () => {
    try {
      const response = await fetch(
        `http://localhost:5006/api/admin/66a8ed2d6cbb8027a4a7c508`,
        {
          method: "GET", // GET is the default method, but it's good practice to specify it explicitly
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()
      if (res) {
        console.log("res premium visa details", res)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    }
  }

  const handleStatus = async id => {
    console.log("status updated ID", id, selectedGroup.value)
    try {
      // Define the API endpoint
      const apiUrl = `${apiURL}/api/admin/visa/status/${id}`
      const response = await fetch(apiUrl, {
        method: "PATCH", // or 'PATCH' if you're using PATCH
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status: selectedGroup.value }),
      })
      console.log("Status updated successfully:", response)
      if (!response.ok) {
        setModal(!modal)
        throw new Error("Network response was not ok")
      }
      const data = await response.json()
      setModal(!modal)
      // alert("Status Updated Successfully.")
      toast("Status updated successfully!")
      fetchPremiumlist()
      console.log("Status updated successfully:", data)
    } catch (error) {
      console.error("Error updating status:", error)
    }
  }

  const handleDetails = async visaID => {
    console.log("visa services details here.", visaID)
    return navigate(`/service/details/${visaID}`)
  }

  useEffect(() => {
    //fetchUsers()
    fetchPremiumlist()
    //fetchPremiumVisaDetails()
  }, [])

  const rows = visaList.map((user, i) => ({
    ...user,
    ID: i + 1,
    fullName: (
      <p
        style={{ cursor: "pointer", color: "blue" }}
        onClick={() => handleDetails(user._id)}
      >
        {user?.userId?.fullName}
      </p>
    ),
    email: user?.userId?.email,
    Country: "Mauritius",
    createdAt: user.createdAt?.substring(0, 10),
    actions: (
      <Button type="submit" color="success" style={{ fontSize: "12px" }} onClick={() => toggle(user?._id)}>
        Update Status
      </Button>
    ),
    mobile: "6345354526",
  }))

  //datatables here.
  const data = {
    columns: [
      {
        label: "#ID",
        field: "ID",
        sort: "asc",
        width: 20,
      },
      {
        label: "Full Name",
        field: "fullName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Jurisdiction",
        field: "jurisdiction",
        sort: "asc",
        width: 100,
      },
      {
        label: "Service Name",
        field: "serviceName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Country",
        field: "Country",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mobile No",
        field: "mobile",
        sort: "asc",
        width:100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Create Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "actions",
        // sort:"asc",
        width: 150,
      },
    ],
    rows,
  }

  document.title = "Visa Service | Admin & Dashboard"

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="OnlineKorp"
            title="Visa"
            breadcrumbItem="Visa Users"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#6f42c1"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <MDBDataTable responsive striped bordered data={data} />
                      // <Table className="table mb-0">
                      //   <thead>
                      //     <tr>
                      //       <th>#ID</th>
                      //       <th>Full Name</th>
                      //       <th>Email</th>
                      //       <th>Jurisdiction</th>
                      //       {/* <th>Table heading</th> */}
                      //       <th>Type of Service</th>
                      //       <th>Status</th>
                      //       <th>Date</th>
                      //       <th>Actions</th>
                      //     </tr>
                      //   </thead>
                      //   <tbody>
                      //     {visaList && visaList.length > 0 ?
                      //       visaList?.map((el, i) => (
                      //         <>
                      //           <tr>
                      //             <th scope="row">{i + 1}</th>
                      //             <td
                      //               onClick={() => handleDetails(el?._id)}
                      //               style={{ cursor: "pointer", color: "blue" }}
                      //             >
                      //               {el?.userId?.fullName}
                      //             </td>
                      //             <td>{el?.userId?.email}</td>
                      //             <td>{el?.jurisdiction}</td>
                      //             <td>{el?.serviceName}</td>
                      //             <td>{el?.status}</td>
                      //             <td role="button" className="text-primary">
                      //               {el?.createdAt?.substring(0, 10)}
                      //             </td>
                      //             <td>
                      //               <div className="btn-group">
                      //                 <Button
                      //                   type="submit"
                      //                   color="success"
                      //                   onClick={() => toggle(el?._id)}
                      //                 >
                      //                   Update Status
                      //                 </Button>
                      //               </div>
                      //             </td>

                      //           </tr>
                      //         </>
                      //       )) :
                      //       (
                      //         <tr>
                      //           <td colSpan="6" style={{ textAlign: "center", padding: "20px" }}>
                      //             Services Not Found.
                      //           </td>
                      //         </tr>
                      //       )
                      //     }
                      //   </tbody>
                      // </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {
        <Status
          modal={modal}
          toggle={toggle}
          id={updateID}
          selectedGroup={selectedGroup}
          setselectedGroup={setselectedGroup}
          handleStatus={handleStatus}
        />
      }
    </>
  )
}
