import React from "react";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Utility
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import { VisaService } from "pages/Dashboard/services/VisaService";
import Users from "pages/Users";
import Service from "pages/service";
import ServiceDetails from "pages/ServiceDetails";
import GoAML from "pages/Dashboard/goAML/GoAML";
import GoAmlDetails from "pages/GoAmlDetails";
import { components } from "react-select";
import Company from "pages/Dashboard/company/Company";
import CompanyDetail from "pages/CompanyDetail";


const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/users", component: <Users /> },
  { path: "/service/:id", component: <Service /> },
  // { path: "/service/details/:visaID", component: <ServiceDetails /> },

  //services 
  { path: "/services/visa", component: <VisaService /> },
  { path: "/services/goAml", component: <GoAML /> },
  { path: "/services/company", component: <Company /> },
  { path: "/service/details/:goAmlID", component: <GoAmlDetails /> },
  { path: "/service/company/details/:companyID", component: <CompanyDetail /> },


  //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "*", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
];

export { userRoutes, authRoutes };
