import Status from "components/Common/model/Status"
import { MDBDataTable } from "mdbreact"
import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
} from "reactstrap"

const Service = () => {
    const { id } = useParams()
    const [modal, setModal] = useState(false)
    const [updateID, setUpdateID] = useState(null)
    const [visaData, setVisaData] = useState([])
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate()
    const [selectedGroup, setselectedGroup] = useState(null)
    const [loading, setLoading] = useState(true) // New loading state
    const apiURL = `https://apionlinekorp.radarsofttech.in`
   // let apiURL = `http://localhost:5006`
    //let apiURL = `http://localhost:8080`
    const fetchServices = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${apiURL}/api/admin/user/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const res = await response.json()
            if (res) {
                console.log(
                    "res premium visa details///////////////////////////////////",
                    res
                )
                if (res.message == "Token verification failed") {
                    navigate("/login")
                  }
                setVisaData(res.data)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false) // Set loading to false after fetching
        }
    }

    const toggle = (itemID = null) => {
        setModal(!modal)
        setUpdateID(itemID)
    }
    document.title = "Visa Service | Admin & Dashboard"

    const handleVisaDetails = async (visaID, serviceName) => {
        console.log("visaID, serviceName", visaID, serviceName)
        if (serviceName === "goAML") {
            return navigate(`/service/details/${visaID}`)
        } else {
            const companyID = visaID
            return navigate(`/service/company/details/${companyID}`)
        }
    }

    const handleStatus = async id => {
        console.log("status updated ID", id, selectedGroup.value)
        try {
            // Define the API endpoint
            const apiUrl = `${apiURL}/api/admin/visa/status/${id}`
            const response = await fetch(apiUrl, {
                method: "PATCH", // or 'PATCH' if you're using PATCH
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ status: selectedGroup.value }),
            })
            console.log("Status updated successfully:", response)
            if (!response.ok) {
                setModal(!modal)
                throw new Error("Network response was not ok")
            }
            const data = await response.json()
            setModal(!modal)
            //   alert("Status Updated Successfully.")
            toast("Status updated successfully!")
            fetchServices()
            console.log("Status updated successfully:", data)
        } catch (error) {
            console.error("Error updating status:", error)
        }
    }

    useEffect(() => {
        fetchServices()
    }, [])
    const rows = visaData?.map((user, i) => ({
        ID: i + 1, // Unique ID for each row
        fullName: (
            <p
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => handleVisaDetails(user?.products, user?.serviceName)}
            >
                {user?.userId?.fullName}
            </p>
        ),
        email: user?.userId?.email,
        serviceName: user?.serviceName, // No need for extra curly braces
        Country: "Mauritius",
        createdAt: user.createdAt?.substring(0, 10) || "Date not available",
        mobile: "6345354526",
    }))

    //datatables here.
    const data = {
        columns: [
            {
                label: "#ID",
                field: "ID",
                sort: "asc",
                width: 100,
            },
            {
                label: "Full Name",
                field: "fullName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Country",
                field: "Country",
                sort: "asc",
                width: 150,
            },
            {
                label: "service Name",
                field: "serviceName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Mobile No",
                field: "mobile",
                sort: "asc",
                width: 150,
            },
            {
                label: "Create Date",
                field: "createdAt",
                sort: "asc",
                width: 150,
            },
        ],
        rows,
    }

    document.title = "OnlineKorp Services | Admin & Dashboard"

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        {loading ? (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <ThreeDots
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#6f42c1"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            <MDBDataTable responsive striped bordered data={data} />
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                <Status
                    modal={modal}
                    toggle={toggle}
                    id={updateID}
                    selectedGroup={selectedGroup}
                    setselectedGroup={setselectedGroup}
                    handleStatus={handleStatus}
                />
            }
        </>
    )
}
export default Service
