import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import {
  postJwtLogin,
  postJwtLogOut,
} from "../../../helpers/fakebackend_helper";
import { Alert } from "reactstrap";
import { toast } from "react-toastify";


function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      console.log("enter into the saga call.")
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      console.log("response code here.", response);
      localStorage.setItem("Admintoken", response.token);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    // alert("Admin Login successFul!")
    toast("Admin login successful!")
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogOut);
      yield put(logoutUserSuccess(response));
    }
    history("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
